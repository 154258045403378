import { graphql, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { useSelector } from "react-redux";
import {
  PrismicProductHome,
  PrismicProductHomeDataType,
  PrismicProductHomeEdge,
} from "types/declarations";
import { RootState } from "utils/store/store";

export interface ProductPageProps {
  productName: string;
  handleHubspot?: () => void;
}

export const useProductHomeData = ({
  productName,
}: ProductPageProps): PrismicProductHomeDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicProductHome {
        totalCount
        edges {
          node {
            lang
            tags
            _previewable
            data {
              hero_header {
                raw
              }
              hero_tags {
                raw
              }
              hero_description {
                raw
              }
              hero_button {
                raw
              }
              hero_image {
                url
                alt
              }
              explainer_video_url {
                url
              }
              navbar_elements {
                raw
              }
              product_navbar_image {
                url
                alt
              }
              first_section_anwser {
                raw
              }
              first_section_description {
                raw
              }
              first_section_text {
                raw
              }
              functions_header {
                raw
              }
              functions_subheader {
                raw
              }
              learn_more_button {
                raw
              }
              learn_more_header {
                raw
              }
              usage_header {
                raw
              }
              usage_subheader {
                raw
              }
              usage_learn_more {
                raw
              }
              product_cta_button {
                raw
              }
              body {
                ... on PrismicProductHomeDataBodyImageSlice {
                  id
                  slice_type
                  primary {
                    image_url {
                      url
                    }
                    image {
                      gatsbyImageData
                      url
                      alt
                    }
                    left_button_text {
                      richText
                    }
                    right_button_text {
                      richText
                    }
                    right_button_mobile_text {
                      richText
                    }
                    slice_description {
                      richText
                    }
                    slice_title {
                      richText
                    }
                    video_url {
                      raw
                    }
                    right_button_url {
                      raw
                    }
                    left_button_url {
                      raw
                    }
                  }
                }
                ... on PrismicProductHomeDataBodyBenefitsSlice {
                  id
                  slice_type
                  items {
                    tile_title {
                      richText
                    }
                    tile_content {
                      richText
                    }
                    tile_image {
                      gatsbyImageData
                      url
                      alt
                    }
                  }
                  primary {
                    business_benefits_section_title {
                      richText
                    }
                    business_benefits_section_link {
                      url
                    }
                  }
                }
                ... on PrismicProductHomeDataBodyAccessDataSlice {
                  id
                  slice_type
                  items {
                    tile_description {
                      richText
                    }
                    tile_icon {
                      gatsbyImageData
                      url
                      alt
                    }
                    tile_icon_active {
                      url
                      alt
                    }
                    tile_image {
                      gatsbyImageData
                      alt
                      url
                    }
                    title_title {
                      richText
                    }
                  }
                  primary {
                    title {
                      richText
                    }
                    subtitle {
                      richText
                    }
                    button_link {
                      raw
                    }
                    button_text {
                      richText
                    }
                  }
                },
                ... on PrismicProductHomeDataBodyCapabilitiesSlice {
                  id
                  slice_type
                  primary {
                    capabilities_section_title {
                      richText
                    }
                  }
                  items {
                    capabilities_element_button_text {
                      richText
                    }
                    capabilities_element_description {
                      richText
                    }
                    capabilities_element_full_text {
                      richText
                    }
                    capabilities_element_image {
                      gatsbyImageData
                      alt
                      url
                    }
                    capabilities_element_link {
                      raw
                    }
                    capabilities_element_title {
                      richText
                    }
                  }
                }
                ... on PrismicProductHomeDataBodyPredictionModelsSlice {
                  id
                  slice_type
                  items {
                    model_img {
                      gatsbyImageData
                      url
                      alt
                    }
                    model_description {
                      richText
                    }
                    model_title {
                      richText
                    }
                  }
                  primary {
                    button_text {
                      richText
                    }
                    button_url {
                      raw
                    }
                    title {
                      richText
                    }
                  }
                }
                ... on PrismicProductHomeDataBodyTalkSlice {
                  id
                  slice_type
                  primary {
                    talk_button_link {
                      raw
                    }
                    talk_button_text {
                      richText
                    }
                    talk_description {
                      richText
                    }
                    talk_image {
                      gatsbyImageData
                      alt
                      url
                    }
                    talk_title {
                      richText
                    }
                  }
                }
                ... on PrismicProductHomeDataBodySuccessStories {
                  id
                  slice_type
                  items {
                    author {
                      richText
                    }
                    button_link {
                      raw
                    }
                    button_text {
                      richText
                    }
                    company_logo {
                      gatsbyImageData
                      url
                      alt
                    }
                    description {
                      richText
                    }
                    number
                    number_description {
                      richText
                    }
                    percent
                    percent_description {
                      richText
                    }
                    second_number
                    second_number_description {
                      richText
                    }
                    subtitle {
                      richText
                    }
                    title {
                      richText
                    }
                  }
                }
                ... on PrismicProductHomeDataBodyHeroCardSlice {
                  id
                  slice_type
                  primary {
                    title {
                      richText
                    }
                    description {
                      richText
                    }
                    left_button_text {
                      richText
                    }
                    left_button_url {
                      raw
                    }
                    right_button_text {
                      richText
                    }
                    right_button_url {
                      raw
                    }
                    pointer_img {
                      url
                      alt
                    }
                    video_overlay_img {
                      url
                      alt
                    }
                    video_url {
                      raw
                    }
                    video_play_button_text {
                      richText
                    }
                    footer {
                      richText
                    }
                  }
                }
                ... on PrismicProductHomeDataBodyBrandCarouselSlice {
                  id
                  slice_type
                  items {
                    client_image {
                      alt
                      url
                    }
                  }
                }
                ... on PrismicProductHomeDataBodyReviewsSlice {
                  id
                  slice_type
                  primary {
                    button_text {
                      richText
                    }
                  }
                  items {
                    company_logo_img {
                      gatsbyImageData
                      url
                      alt
                    }
                    employee_portrait_img {
                      gatsbyImageData
                      url
                      alt
                    }
                    review {
                      richText
                    }
                    employee_name {
                      richText
                    }
                    employee_role {
                      richText
                    }
                    button_url {
                      raw
                    }
                  }
                }
                ... on PrismicProductHomeDataBodyCalendarSlice {
                  id
                  slice_type
                  primary {
                    title {
                      richText
                    }
                    description {
                      richText
                    }
                  }
                }
                ... on PrismicProductHomeDataBodyBrandCarouselSlice {
                  id
                  slice_type
                  items {
                    client_image {
                      alt
                      url
                    }
                  }
                }
              }
              product_cta_description {
                raw
              }
              product_cta_header {
                raw
              }
              usage_example {
                description {
                  raw
                }
                example_brand_image {
                  url
                  alt
                }
                example_brand_text {
                  raw
                }
                example_button {
                  raw
                }
                example_header {
                  raw
                }
                example_link {
                  raw
                }
                example_tags {
                  raw
                }
                header {
                  raw
                }
              }
              seo_description {
                text
              }
              seo_canonical_url {
                url
              }
              seo_title {
                text
              }
              functions_sections {
                descriptions {
                  raw
                }
                subheader {
                  raw
                }
                header {
                  raw
                }
                image {
                  url
                  alt
                }
              }
            }
          }
        }
      }
    }
  `);
  
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicProductHome.edges
    .map((edge: PrismicProductHomeEdge) => edge.node)
    .filter((node: PrismicProductHome) => node.lang === language)
    .filter((node: PrismicProductHome) => node.tags.includes(productName))
    .map((node: PrismicProductHome) => node.data)[0];
};
