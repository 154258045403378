import React, { useMemo } from 'react'
import { colors, productColors } from 'shared/constants'
import styled, { css } from 'styled-components'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import StyledEmployeeImg from './StyledEmployeeImg'
import { RichText } from 'prismic-reactjs'
import ArrowRightIcon from 'assets/arrowRight.svg'
import { Maybe, PrismicProductHomeDataBodyReviewsSliceItem, PrismicProductHomeDataBodyReviewsSlicePrimary } from 'types/declarations'
import { IGatsbyImageData } from 'gatsby-plugin-image'

const StyledCustomerInfoDiv = styled.div(() => {
  const { mobile } = useMediaQuery()
  
  return css`
    display: flex;
    flex-direction: ${mobile ? 'column' : 'row'};
    gap: 2rem;
  `
})

const StyledInfoDiv = styled.div(() => {
  const { desktop, laptop } = useMediaQuery()

  return css`
    display: flex;
    width: 100%;
    flex-direction: ${(desktop || laptop) ? 'row' : 'column'};
    gap: 1rem;
  `
})

const StyledEmployeeDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const StyledEmployeeName = styled.h6`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.625rem;
  margin: 0;
  color: ${colors.darkBlue};
`

const StyledEmployeeRole = styled.p`
  font-weight: 400;
  size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
  color: ${colors.darkBlue};
`

const StyledDivider = styled.div(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    height: ${(tablet || mobile) ? 'none' : '100%'};
    width: ${(tablet || mobile) ? '100%' : 'none'};
    border: ${`1px solid ${colors.gray.light}`};
  `
})

const StyledNavButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0.5rem 0.5rem 0;
  gap: 0.5rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${productColors.violet.dark};

  svg {
    path {
      stroke: ${productColors.violet.dark};
    }
  }
`

interface CustomerInfoProps {
  selectedEmployeeImgUrl?: IGatsbyImageData
  selectedReview?: Maybe<PrismicProductHomeDataBodyReviewsSliceItem>
  primary?: Maybe<PrismicProductHomeDataBodyReviewsSlicePrimary>
}

function CustomerInfo({
  selectedEmployeeImgUrl,
  selectedReview,
  primary
}: CustomerInfoProps) {
  const { mobile, tablet } = useMediaQuery()
  const buttonUrl = useMemo(() => selectedReview?.button_url?.raw.url, [selectedReview])

  return (
    <StyledCustomerInfoDiv>
      {(mobile || tablet) && selectedEmployeeImgUrl
        ? <StyledEmployeeImg image={selectedEmployeeImgUrl} alt='employee'/>
        : null
      }
      <StyledInfoDiv>
        <StyledEmployeeDetailsDiv>
          <StyledEmployeeName>{RichText.asText(selectedReview?.employee_name?.richText)}</StyledEmployeeName>
          <StyledEmployeeRole>{RichText.asText(selectedReview?.employee_role?.richText)}</StyledEmployeeRole>
        </StyledEmployeeDetailsDiv>
        <StyledDivider />
        {buttonUrl
          ? (
            <StyledNavButton onClick={() => window.location.assign(buttonUrl)}>
              {RichText.asText(primary?.button_text?.richText)}
              <ArrowRightIcon />
            </StyledNavButton>
          )
          : null
        }
      </StyledInfoDiv>
    </StyledCustomerInfoDiv>
  )
}

export default CustomerInfo
