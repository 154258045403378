import "./BlogItems.scss";
import React, { FC, useCallback } from "react";
import styled, {css} from 'styled-components'
import { BlogPost, Tag } from "features/blog/blogModel";
import { Link } from "gatsby";
import BtnArrowRight from "assets/arrowbtn.svg";
import Placeme from "assets/placeme-location-contact.svg";
import Target from "assets/placeme-target-contact.svg";
import Source from "assets/placeme-source-contact.svg";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { GatsbyImage } from "gatsby-plugin-image";

const Span = styled.span(({productColor}:{productColor: string})=>{
  return css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color:${productColor};
  margin-right: 10px;
  font-weight: 600;
  svg{
    margin-right: 12px;
    path{
      fill: ${productColor};
    }
  }
`
})

const StyledGatsbyImage = styled(GatsbyImage)`
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  max-height: 225px;
  object-fit: cover;
  width: 100%;
`

export interface BlogItemProps {
  item: BlogPost;
}

const BlogItem: FC<BlogItemProps> = ({ item }: BlogItemProps) => {
  const { language } = useSelector((state: RootState) => state.i18n);

  const getTags = useCallback((tag: Tag) => {
    const title = tag?.value;
    if (title?.includes("placeme")) {
      return (
        <Span productColor="#362FB7">
          <Placeme width='18' height='18'/>
          {title}
        </Span>
      );
    } else if (title?.includes("target")) {
      return (
        <Span productColor="#CC3C00">
        <Target width='18' height='18'/>
         {title}
     </Span>
      );
    } else if (title?.includes("source")) {
      return (
        <Span productColor="#6830B2">
        <Source width='18' height='18'/>
         {title}
     </Span>
      );
    } else {
      return <Span productColor="#001d62" >{title}</Span>;
    }
  }, []);

  return (
    <div className="blog-item">
      <StyledGatsbyImage image={item?.image?.gatsbyImageData} alt={item?.image?.alt || 'blog post image'} />
      <div>
        <h3 className="blog-item__title">{item?.title}</h3>
        <div className="blog-item__tags">
          {item?.tags?.map((tag, index) => {
            return <React.Fragment key={index}>{getTags(tag)}</React.Fragment>;
          })}
        </div>
        <p className="blog-item__description">
          {item.description.length > 50
            ? item?.description?.substr(0, 80) + "..."
            : item?.description}
        </p>
        <Link className="blog-item__link" to={language === 'pl' || language === 'en-pl' ? `${item?.link}/` : `/en-pl/blog/${item?.link}/`}>
          {language === 'pl' ? "Czytaj więcej" : 'Read more'} <BtnArrowRight />
        </Link>
      </div>
    </div>
  );
};

export default BlogItem;
