import * as React from "react";
import "../layout/Layout.scss";
import { FC } from "react";
import Footer from "features/footer/Footer";
import RootContainer from "containers/RootContainer";
import Header from "components/shared-components/header/Header";
import ProductSecondNavbar from "features/product-page/product-navbar/ProductSecondNavbar";
import "ui/product-header/ProductHeader.scss";
import { useProductHomeData } from "features/product-page/product-homepage/productHomeService";
import styled from 'styled-components';
import { colors, pageUrls } from 'shared/constants';
import { usePathNamesIncludes } from "utils/hooks/usePathNamesIncludes";

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: ${colors.background};
`

export interface ProductLayoutProps {
  children: React.ReactNode;
  productName: string;
  handleHubspot?: () => void;
}

const ProductLayout: FC<ProductLayoutProps> = ({
  children,
  productName,
  handleHubspot,
}: ProductLayoutProps) => {
  const data = useProductHomeData({productName, handleHubspot});
  const includesPlacemePaths = usePathNamesIncludes(pageUrls.productPlaceme);

  return (
    <RootContainer>
      <Header />
      <header className="product-header__sec">
        {!includesPlacemePaths
          ?  <ProductSecondNavbar handleHubspot={handleHubspot} imageUrl={data?.product_navbar_image?.url ?? ""} />
          : null
        }
      </header>
      <StyledMain>{children}</StyledMain>
      <Footer />
    </RootContainer>
  );
};

export default ProductLayout;
