import * as React from 'react';
import './BlogPost.scss';
import { RichText } from 'prismic-reactjs';
import { useBlogPost, useBlogPostHomePageData } from './blogPostService';
import Button from 'ui/ButtonComponent/Button';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { RootState } from 'utils/store/store';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'utils/hooks/useMediaQuery';
import { GatsbyImage } from 'gatsby-plugin-image';

const BlogPostInner = styled.div(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    max-width: ${mobile || tablet ? 768 : 1000}px;
  `;
});

const BoxContainerStyled = styled.div(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding-bottom: 25px;
    width: 100%;
    gap: 4%;
    height: fit-content;
    ${tablet &&
    css`
      height: fit-content;
      align-items: stretch;
      -ms-overflow-style: none;
      scrollbar-width: none;
      scroll-behavior: smooth;
      gap: 30px ${mobile || tablet ? '7.5%' : '3.5%'};
      overflow-y: scroll;
      scroll-snap-type: x mandatory;
      padding: 1.5rem;
      &::-webkit-scrollbar {
        display: none;
      }
    `}
    ${mobile &&
    css`
      height: 43rem;
      align-items: stretch;
      -ms-overflow-style: none;
      scrollbar-width: none;
      scroll-behavior: smooth;
      gap: 30px ${mobile || tablet ? '7.5%' : '3.5%'};
      overflow-y: scroll;
      scroll-snap-type: x mandatory;
      padding: 1.5rem;
      &::-webkit-scrollbar {
        display: none;
      }
    `}
  `;
});
const TitleStyled = styled.span(() => {
  return css`
    font-size: 16px;
    line-height: 52px;
    letter-spacing: 2px;
    color: #001c61;
    text-transform: uppercase;
    text-align: center;
    margin-top: 2rem;
  `;
});
const HeaderStyled = styled.p(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    font-size: 1.5rem;
    line-height: 52px;
    text-align: center;
    color: #001c61;
    font-weight: 700rem;
    margin: 1rem 0 3rem 0;
    letter-spacing: 0.1rem;
    scroll-snap-align: center;
    ${mobile &&
    css`
      font-size: 1.2rem;
      letter-spacing: 0;
      margin-bottom: 1rem;
    `}
    ${tablet &&
    css`
      font-size: 30px;
      line-height: 38px;
      padding: 1.5rem 10px;
      margin: 0;
    `}
  `;
});

const ButtonDiv = styled.div(() => {
  const { tablet, mobile } = useMediaQuery();
  return css`
    display: flex;
    margin-left: 1.5rem;
    padding: 2rem 0;
    ${tablet &&
    css`
      margin-left: 0;
      padding: 0;
    `}
    ${mobile &&
    css`
      margin-left: 1rem;
      padding: 0.5rem 0px 0rem;
    `}
  `;
});

const KnowMoreButtonDiv = styled.div(() => {
  const { laptop, desktop } = useMediaQuery();
  return css`
    display: flex;
    padding-bottom: 3.19rem;
    align-items: center;
    justify-content: center;

    ${(laptop || desktop) &&
    css`
      padding-top: 3rem;
    `}
  `;
});

const BlogPostTitleStyled = styled.p(() => {
  const { mobile, tablet, laptop } = useMediaQuery();
  return css`
    font-size: 18px;
    line-height: 33px;
    color: #001d62;
    text-align: left;
    margin-top: 1.5rem;
    padding: 0 1.5rem;
    height: 7rem;
    white-space: pre-wrap;
    ${mobile &&
    css`
      font-size: 1rem;
      max-width: 100%;
      margin-top: 1rem;
      height: 20%;
    `};
    ${laptop &&
    css`
      line-height: 28px;
      font-size: 1rem;
      max-width: 100%;
      margin-top: 1rem;
      height: 23%;
    `};
    ${tablet &&
    css`
      font-size: 1.1rem;
      max-width: 100%;
      margin-top: 1.2rem;
      line-height: 28px;
      height: 20%;
      padding: 0 1.5rem 0 0;
    `};
  `;
});

const BlogPostDescriptionStyled = styled.div(() => {
  const { mobile, tablet, laptop } = useMediaQuery();
  return css`
    font-size: 18px;
    line-height: 33px;
    color: #001d62;
    text-align: left;
    width: 100%;
    padding: 0 1.5rem;
    height: 12rem;
    white-space: pre-wrap;
    ${mobile &&
    css`
      font-size: 1rem;
      padding: 1rem 1.5rem 0 1.5rem;
    `};
    ${laptop &&
    css`
      font-size: 1rem;
    `};
    ${tablet &&
    css`
      line-height: 28px;
      font-size: 1rem;
      padding: 1rem 1rem 1rem 0;
      margin-right: 2rem;
      height: 50%;
    `};
  `;
});

const BlogPostGatsbyImageStyled = styled(GatsbyImage)(() => {
  const { tablet, mobile } = useMediaQuery();
  return css`
    width: 100%;
    height: 16rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    ${tablet &&
    css`
      height: auto;
      width: 18rem;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-top-right-radius: 0;
    `};
    ${mobile &&
    css`
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    `};
  `;
});

const Box = styled.div(() => {
  const { mobile, tablet } = useMediaQuery();
  return css`
    width: 90%;
    background: #fff;
    box-shadow: 0px 0px 35px 0px rgba(36, 33, 84, 0.12);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    scroll-snap-align: center;
    ${tablet &&
    css`
      flex-direction: row;
      margin: 30px ${mobile || tablet ? '50px' : '5%'};
      max-height: 350px;
      min-width: 668px;
      display: flex;
      scroll-snap-align: center;
      gap: 1rem;
    `}
    ${mobile &&
    css`
      min-width: 330px;
      flex-direction: column;
      margin: 0 1rem 0 1rem;
      scroll-snap-align: center;
    `}
  `;
});

const BlogPost = (): JSX.Element => {
  const blogPosts = useBlogPost();
  const general_data = useBlogPostHomePageData();
  const { language } = useSelector((state: RootState) => state.i18n);
  const { mobile, tablet } = useMediaQuery();
  const data = [...blogPosts]
    ?.sort(
      (a, b) =>
        parseInt(a.order?.text as string) - parseInt(b.order?.text as string)
    )
    .slice(0, 3);
  const scrollContainer = useRef() as React.MutableRefObject<HTMLDivElement>;

  const [currentCardIndex, setCurrentCardIndex] = React.useState(0);

  const scrollToIndex = (index: number) => {
    if (currentCardIndex === index) {
      return;
    }
    scrollContainer.current.scrollLeft =
      index * scrollContainer.current.clientWidth;
  };

  return (
    <>
      {data?.length > 0 && (
        <section className='blog-post'>
          <BlogPostInner className='blog-post__inner'>
            <TitleStyled>
              {RichText.asText(general_data?.blog_post_main_title?.raw)}
            </TitleStyled>
            <HeaderStyled>
              {RichText.asText(general_data?.blog_post_header?.raw)}
            </HeaderStyled>
            <div className='carousel-container'>
              <BoxContainerStyled
                ref={scrollContainer}
                onScroll={() => {
                  const newIndex = Math.round(
                    scrollContainer.current.scrollLeft /
                      scrollContainer.current.clientWidth
                  );
                  if (newIndex === currentCardIndex) {
                    return;
                  }
                  setCurrentCardIndex(newIndex);
                }}>
                {data?.map((item, index) => (
                  <Box key={`${item.seo_title}-${index}`}>
                    <BlogPostGatsbyImageStyled
                      image={item?.blog_image?.gatsbyImageData ?? ''}
                      alt={item?.blog_image?.alt ?? ''}
                    />
                    <div>
                      <BlogPostTitleStyled>
                        {RichText.asText(item?.seo_title?.raw || [])}
                      </BlogPostTitleStyled>
                      <BlogPostDescriptionStyled>
                        {RichText.asText(item?.seo_description?.raw || [])}
                      </BlogPostDescriptionStyled>
                      <ButtonDiv>
                        <Button
                          to={`/${language}/blog/${item?.link?.text}/`}
                          as='navLink'
                          reverse={true}
                          arrowRight={true}
                          height='45px'
                          font_weight='500'
                          backgroundCol='transparent'
                          minWidth='168px'>
                          {RichText.asText(
                            general_data?.blog_post_read_more_button?.raw
                          )}
                        </Button>
                      </ButtonDiv>
                    </div>
                  </Box>
                ))}
              </BoxContainerStyled>
            </div>
            {(mobile || tablet) && (
              <div className='carousel-indicators'>
                {data?.map((_, index) => (
                  <div
                    key={index}
                    className={`indicator_wrapper ${
                      index === currentCardIndex ? 'active' : ''
                    }`}
                    onClick={() => scrollToIndex(index)}>
                    <div
                      className={`indicator ${
                        index === currentCardIndex ? 'active' : ''
                      }`}
                    />
                  </div>
                ))}
              </div>
            )}
          </BlogPostInner>

          <KnowMoreButtonDiv>
            <Button
              to={`/${language}/blog/`}
              as='navLink'
              reverse={true}
              arrowRight={true}
              backgroundCol='transparent'
              padding='0.5rem 2.5rem'
              font_weight='500'
              height='3.2rem'>
              {RichText.asText(general_data?.blog_post_main_button?.raw)}
            </Button>
          </KnowMoreButtonDiv>
        </section>
      )}
    </>
  );
};

export default BlogPost;
