import React from 'react'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import styled, { css } from 'styled-components'
import { colors, productColors } from 'shared/constants'
import { Maybe, PrismicProductHomeDataBodyImageSlicePrimary } from 'types/declarations'
import { RichText } from 'prismic-reactjs'
import SharedButton from 'ui/SharedButton/SharedButton'
import { GatsbyImage } from 'gatsby-plugin-image'

const StyledSection = styled.section`
  background-color: ${colors.background};
`

const MainDiv = styled.div(() => {
  const { desktop, laptop, tablet, mobile } = useMediaQuery()

  return css`
    display: flex;
    padding: ${tablet ? '20px 25px' : mobile ? '20px 15px' : '60px 84px'};
    flex-direction: ${(desktop || laptop) ? 'row' : 'column'};
    margin: 0 auto;
    gap: ${(desktop || laptop) ? '2rem' : '1rem'};
    max-width: 1440px;
    align-items: ${mobile ? 'center' : 'none'};
  `
})

const ImageOrVideoDiv = styled.div(() => {
  const { mobile, tablet } = useMediaQuery()

  return css`
  display: flex;
  align-items: center;
  justify-content: ${tablet ? 'none' : 'center'};
  min-width: ${mobile ? '300px' : '500px'};
`})

const StyledGatsbyImage = styled(GatsbyImage)`
  margin-right: 0;
`

const TextDiv = styled.div(() => css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`)

const Title = styled.h1(() => {
  const { mobile } = useMediaQuery()

  return css`
    font-weight: 700;
    font-size: ${mobile ? '26px' : '36px'};
    line-height: ${mobile ? '36px' : '46px'};
    margin-bottom: 0;
    color: ${colors.darkBlue};
  `
});

const Description = styled.div(() => css`
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0;
    color: ${colors.darkBlue};

    em {
      background-color: ${productColors.violet.light};
      font-style: normal;
    }
  }
`)

const ButtonDiv = styled.div(() => {
  const { mobile } = useMediaQuery()

  return css`
    display: flex;
    flex-direction: ${mobile ? 'column' : 'row'};
    gap: 1rem;
  `
})

const StyledVideo = styled.video(() => css`
    height: auto;
    width: inherit;
`)

interface ProductAnalyseBusinessProps {
  slicePrimary: Maybe<PrismicProductHomeDataBodyImageSlicePrimary>
  leftButtonOnClick?(): void
  rightButtonOnClick?(): void
}

function ProductAnalyseBusiness({ slicePrimary, leftButtonOnClick, rightButtonOnClick }: ProductAnalyseBusinessProps) {
  const { mobile } = useMediaQuery()

  const videoUrl = slicePrimary?.video_url?.raw.url
  const imgUrl = slicePrimary?.image?.gatsbyImageData

  return (
    <StyledSection>
      <MainDiv>
        <ImageOrVideoDiv>
          {!!videoUrl
            ? (
              <StyledVideo autoPlay loop playsInline muted>
                <source type='video/mp4' src={videoUrl} />
              </StyledVideo>
            )
            : !!imgUrl
              ? <StyledGatsbyImage alt='image' image={imgUrl} />
              : null
          }
        </ImageOrVideoDiv>
        <TextDiv>
          <Title>{RichText.asText(slicePrimary?.slice_title?.richText)}</Title>
            <Description>{RichText.render(slicePrimary?.slice_description?.richText)}</Description>
          <ButtonDiv>
              <SharedButton 
                buttonProps={{
                  $minWidth: mobile ? '100%' : 'none',
                  $color: productColors.violet.dark,
                  $bgColor: colors.white
                }} 
                redirectsTo={slicePrimary?.left_button_url?.raw.url}
                onClick={leftButtonOnClick}
              >
                {RichText.asText(slicePrimary?.left_button_text?.richText)}
              </SharedButton>
              <SharedButton
                buttonProps={{
                  $minWidth: mobile ? '100%' : 'none',
                  $bgColor: productColors.violet.dark,
                  $color: colors.white,
                }}
                onClick={mobile ? rightButtonOnClick : undefined}
                redirectsTo={mobile ? undefined : slicePrimary?.right_button_url?.raw.url}
              >
                {RichText.asText(mobile ? slicePrimary?.right_button_mobile_text?.richText : slicePrimary?.right_button_text?.richText)}
              </SharedButton>
          </ButtonDiv>
        </TextDiv>
      </MainDiv>
    </StyledSection>
  )
}

export default ProductAnalyseBusiness
