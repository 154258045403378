import { RichText } from "prismic-reactjs";
import * as React from "react";
import { colors, productColors } from "shared/constants";
import styled, { css } from "styled-components";
import { PrismicProductHomeDataBodyTalkSlice } from "types/declarations";
import Button from "ui/ButtonComponent/Button";
import BtnArrowRight from "assets/arrowRight.svg";
import { useMediaQuery } from "utils/hooks/useMediaQuery";
import { GatsbyImage } from "gatsby-plugin-image";

const SectionWrapper = styled.section(() => {
  const { mobile, tablet } = useMediaQuery()

  return css`
    background-color: ${colors.background};
    padding: 10px 84px 72px 84px;

    ${tablet && 'padding: 40px 25px'}
    ${mobile && 'padding: 20px 15px'}
  `
});

const TalkWrapper = styled.div(() => {
  const { mobile, tablet } = useMediaQuery()

  return css`
    padding: ${tablet ? '30px 25px;' : mobile ? '15px 15px 20px 15px;' : '20px 20px 20px 50px;'};
    max-width: 1288px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    background-color: ${productColors.violet.dark};
    border-radius: 15px;

    ${mobile && css`
      padding: 20px 15px;
      flex-direction: column-reverse;
    `}
  `
});

const Content = styled.div(() => {
  const { mobile } = useMediaQuery()

  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${!mobile && css` max-width: 65%;`}
    color: ${productColors.white};
  `
});

const Title = styled.h1(() => {
  const { mobile } = useMediaQuery()

  return css`
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 8px;

    ${mobile && css`
      font-size: 23px;
      line-height: 34px;
      max-width: 100%;
      margin-top: 14px;
    `}
  `
});

const Description = styled.div(() => {
  const { mobile } = useMediaQuery()

  return css`
    margin-bottom: 0;
  
    p {
      font-size: 18px;
      line-height: 26px;
      font-weight: 400;
      margin-bottom: ${mobile ? '14px' : '5px'};
      color: ${productColors.white};
      padding-bottom: 0;
    }
  `
});

const StyledButton = styled(Button)(() => {
  const { mobile } = useMediaQuery()
  
  return css`
    margin-top: ${mobile ? '0px' : '10px'};
    font-size: 1rem;
    width: fit-content;
    ${mobile ? css`height: 50px;` : css`max-height: 2.5rem;`}
    
    color: ${productColors.violet.dark};
    ${mobile && css`width: '100%';`}
    svg {
      position: relative;
      left: -8px;
      path {
        stroke: ${productColors.violet.dark};
      }
    }

    &:hover {
      background-color: ${productColors.white};
      color: ${productColors.violet.dark};
      svg {
        transform: translateX(10px);
        transition: transform 0.3s ease-in-out;
        path {
          stroke: ${productColors.violet.dark};
        }
      }
    }
  `
});

const StyledGatsbyImage = styled(GatsbyImage)(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    border-radius: 20px;

    ${tablet && css`
      width: 213px;
      object-fit: cover;
    `}

    ${mobile && css`
      width: 160px;
      height: 163px;
      object-fit: cover;
    `}
  `
});

interface TalkSectionProps {
  slice: PrismicProductHomeDataBodyTalkSlice,
  handleOpenCalendar: () => void,
  handleScrollToCalendar: () => void
}

const TalkSection: React.FC<TalkSectionProps> = ({ slice, handleOpenCalendar, handleScrollToCalendar }) => {
  const { mobile } = useMediaQuery();
  const handleClick = () => mobile ? handleScrollToCalendar() : handleOpenCalendar()

  return (
    <SectionWrapper>
      <TalkWrapper>
        <Content>
          <Title>
            {RichText.asText(slice?.primary?.talk_title?.richText)}
          </Title>
          <Description>{RichText.render(slice?.primary?.talk_description?.richText)}</Description> 
          <StyledButton onClick={handleClick} reverse minWidth={mobile ? '100%' : 'none'}>
            {RichText.asText(slice?.primary?.talk_button_text?.richText)}
            <BtnArrowRight />
          </StyledButton>
        </Content>
        <StyledGatsbyImage 
          image={slice.primary?.talk_image?.gatsbyImageData || ''} 
          alt={slice.primary?.talk_image?.alt || ''} 
        />
      </TalkWrapper>
    </SectionWrapper>
  );
}

export default TalkSection;
