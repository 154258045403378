import { DefaultTheme } from 'styled-components'

export const theme: DefaultTheme = {
  fonts: {
    family: "'Inter', sans-serif",
  },
  colors: {
    main: '#001D62',
    bg: '#FBF7F4',
    dark: '#111111',
    product: {
      location: '#362FB7',
      navigator: '#CC3C00',
      marketing: '#9D0584',
      network: '#0075A7',
      data: '#6830B2',
      violet: '#331A6D',
    },
  },
  sizes: {
    container: '1440px',
    innerContainer: '1130px',
  },
  breakpoints: {
    veryLarge: '1180px',
    large: '990px',
    tablet: '768px',
    small: '590px',
    mobile: '375px',
  },
}
