import * as React from 'react';
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';
import store from './src/components/utils/store/store';
import { Provider } from 'react-redux';
import { componentResolverMap } from './withPrismicPreviewSettings';
import { ThemeProvider } from 'styled-components';
import { theme } from './src/theme/theme';

const linkResolver = require('./linkResolver');

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <PrismicPreviewProvider
        repositoryConfigs={[
          {
            repositoryName: 'carcavelos',
            linkResolver: linkResolver.linkResolver,
            componentResolver: componentResolverFromMap(componentResolverMap),
          },
        ]}>
        <Provider store={store}>{element}</Provider>
      </PrismicPreviewProvider>
    </ThemeProvider>
  );
};

export const onClientEntry = () => {
  if (typeof window === 'undefined') return

  const link = document.createElement('link')
  link.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap'
  link.rel = 'stylesheet'
  document.head.appendChild(link)
}
