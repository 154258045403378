import React, { useEffect, useCallback, useState } from "react";
import styled, { css } from "styled-components"
import "./PostDetails.scss";
import BlogLayout from "containers/blog-layout/BlogLayout";
import { useDispatch, useSelector } from "react-redux";
import { PageProps } from "gatsby";
import { BlogPostDetails } from "features/blog/blogModel";
import Breadcrumbs from "features/blog/items/breadcrumbs/Breadcrumbs";
import { RootState } from "utils/store/store";
import { Link } from "gatsby";
import {
  Maybe,
  PrismicBlogPostDataBodyImage,
  PrismicBlogPostDataBodyImagePrimary,
  PrismicBlogPostDataBodyLine,
  PrismicBlogPostDataBodyProductBanner,
  PrismicBlogPostDataBodyReportSliceInsidePost,
  PrismicBlogPostDataBodySimilarPosts,
  PrismicBlogPostDataBodySlicesType,
  PrismicBlogPostDataBodyText,
  PrismicBlogPostDataBodyTextPrimary,
  PrismicBlogPostDataBodyTip,
  PrismicBlogPostDataBodyTipPrimary,
} from "types/declarations";
import { RichText } from "prismic-reactjs";
import ProductBanner from "features/blog/post/post-banners/ProductBanner";
import { getBlogPostDetails } from "features/blog/blogSlice";
import InterestedBanner from "./post-banners/InterestedBanner";
import {useMediaQuery} from "utils/hooks/useMediaQuery";
import PlacemeIcon from "assets/placeme-location-contact.svg";
import TargetIcon from "assets/placeme-target-contact.svg";
import SourceIcon from "assets/placeme-source-contact.svg";
import NewsletterBanner from "./post-banners/NewsletterBanner/NewsletterBanner";
import { v4 as uuid } from "uuid";
import PostInsideBanner from './PostInsideBanner'
import { navigate } from "gatsby-link";
import { HubspotMeetingModal } from "ui/HubspotMeetingModal/HubspotMeetingModal";
import BtnArrowRight from "assets/arrowbtn.svg";
import Seo from "utils/seo/Seo";
import { Language, setLanguage } from "utils/i18n/i18nSlice";
import { GenericPopup } from "components/shared-components/popups/generic-popup/GenericPopup";
import { checkForPopup } from "components/shared-components/popups/generic-popup/checkForPopupService";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { GatsbyImage } from "gatsby-plugin-image";
const linkResolver = require('../../../../../linkResolver.js');

const Span = styled.span(({ productColor }: { productColor: string }) => {
  return css`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  background-color: ${productColor} !important;
  color: white;
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border-radius: 8px;
  padding: 7px 12px;
  height: fit-content;
  svg {
    margin-right: ${productColor === "#9D0584" ? "0" : "8px"};
    path {
      fill: white;
    }
  }
`
})

const MainPostGatsbyImage = styled(GatsbyImage)`
  height: 100%;
  margin-left: 20px;
  margin-right: 0;
  max-height: 300px;
  max-width: 600px;
  object-fit: cover;
  width: 100%;
`

const PostDetails = (props: PageProps): JSX.Element => {
  const [showMeeting, setShowMeeting] = useState(false);
  const dispatch = useDispatch();
  const { language } = useSelector((state: RootState) => state.i18n);
  const link = (props.pageContext as Partial<BlogPostDetails>)?.link;
  const { blogPost } = useSelector(
    (state: RootState) => state.blog
  );
  const displayPopup  = checkForPopup()?.page?.text?.split(';')?.filter(p => p !== '')?.includes('blog_post');

  const { mobile } = useMediaQuery()

  const labels = () => {
    if (blogPost?.post_labels?.raw) {
      return RichText.asText(blogPost?.post_labels?.raw || []).split(";");
    } else {
      return [];
    }
  }

  const handleShowMeeting = () => {
    if (!showMeeting && !mobile) {
      setShowMeeting(true);
    } else{
      navigate(`/${language}/contact/`)
    }
  };

  const handleCloseMeeting = () => {
    if (showMeeting) {
      setShowMeeting(false);
    }
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const url = location?.pathname?.split('/').filter(item => item !== '')
  
      const findLangInUrl = (): number => url.findIndex(item => item === 'en-pl' || 'pl' || 'fr-fr' || 'de-de');
      const urlLanguage = url[findLangInUrl()] as Language
      dispatch(setLanguage(urlLanguage));
    }
  }, [])

  useEffect(() => {
    dispatch(getBlogPostDetails({ link }));
  }, [link, language]);

  const similarPosts = blogPost?.content?.filter((item) => item?.slice_type === 'similar_posts')[0]

  const getTags = useCallback((tag: string) => {
    const title = tag;
    if (title?.includes("placeme")) {
      return (
        <Span productColor="#362FB7" key={title + uuid()}>
          <PlacemeIcon width='18' height='18' />
          {title}
        </Span>
      );
    } else if (title?.includes("target")) {
      return (
        <Span productColor="#CC3C00" key={title + uuid()}>
          <TargetIcon width='18' height='18' />
          {title}
        </Span>
      );
    } else if (title?.includes("source")) {
      return (
        <Span productColor="#6830B2" key={title + uuid()}>
          <SourceIcon width='18' height='18' />
          {title}
        </Span>
      );
    } else {
      return <Span productColor="#001d62" key={title + uuid()} >{title}</Span>;
    }
  }, []);

  const getSimilarPosts = () => {
    const posts = similarPosts as PrismicBlogPostDataBodySimilarPosts
    if (posts) {
      return (
        <div className="similar-posts-box">
          {language === 'pl' ? <span>Powiązane artykuły</span> : <span>Related articles</span>}
          {posts?.items?.filter((_, index) => {
            if (mobile) {
              return index < 2
            } else {
              return index < 3
            }
          }).map(item =>
            <Link key={RichText.asText(item?.similar_link?.raw) + uuid()} to={`/${language}/blog${RichText.asText(item?.similar_link?.raw || [])}/`}>
              <div className="post-body__sidebar--item">
                <h3>{RichText.asText(item?.similar_title?.raw || [])}</h3>
                <BtnArrowRight />
              </div>
            </Link>
          )}
        </div>
      )
    } else {
      return null
    }
  }

  const isTableOfContents = (arr: string[]) => {
    if (arr === undefined) return false;
    if (!arr?.length) return false;
    if (arr?.length) return true;
    return false;
  }

  return (
    <>
      <Seo
        title={blogPost?.seo_title}
        description={blogPost?.seo_description}
        canonicalUrl={blogPost?.seo_canonical_url}
      />
      
      { displayPopup && <GenericPopup /> }
      
      <BlogLayout>
        {showMeeting && (
          <HubspotMeetingModal
            closeMeeting={handleCloseMeeting}
            left="25vw"
            show={showMeeting}
            top="8vh"
          />
        )}
        <div className="post-container">
          <div className="post-header">
            <div className="breadcrumbs-container">
              <Breadcrumbs
                first={language === 'pl' ? "Wiedza i raporty" : "Resources"}
                second="Blog"
                third={blogPost?.title}
              />
            </div>
            <div className="post-header__inner">
              <div>
                <div>
                  <span className="post-header__inner--top-label">
                    {RichText.asText(blogPost?.post_category?.raw || [])}
                  </span>
                  <h1 className="post-header__inner--heading">
                    {blogPost?.title}
                  </h1>
                </div>
              </div>
              <div>
                <MainPostGatsbyImage
                  image={blogPost?.image?.gatsbyImageData ? blogPost?.image?.gatsbyImageData : 'https://i.imgur.com/9jnszTG.png'}
                  alt={blogPost?.image?.alt || 'main post image'}
                />
              </div>
            </div>
          </div>
          <div className="post-body">
            <div className="post-body__inner">
              <div className="post-body__left">
                <div className="post-body__left--tags">
                  {language === 'pl' ? <span className="label">PRODUKT</span> : <span className="label">PRODUCTS</span>}
                  {blogPost?.tags?.map((tag, index) => {
                    return <div key={index}>{getTags(tag?.value)}</div>;
                  })}
                </div>
                {isTableOfContents(blogPost?.table_of_contents?.raw) && <div className="table-of-contents">
                  <RichText render={blogPost?.table_of_contents?.raw || []} />
                </div>}
                {blogPost?.content?.map(
                  (slice: Maybe<PrismicBlogPostDataBodySlicesType>, index: number) => {
                    if (slice?.slice_type === "text") {
                      const textSlice = slice as PrismicBlogPostDataBodyText;
                      const text = textSlice?.primary as PrismicBlogPostDataBodyTextPrimary;
                      return <RichText key={index + uuid()} render={text?.text?.raw || []} />;
                    }
                    if (slice?.slice_type === "image") {
                      const imageSlice = slice as PrismicBlogPostDataBodyImage;
                      const image = imageSlice?.primary as PrismicBlogPostDataBodyImagePrimary;
                      return (
                        <div className='img-post' key={index + uuid()}>
                          <GatsbyImage
                            image={image?.image?.gatsbyImageData ?? ''}
                            alt={image?.image?.alt ?? 'post image'}
                          />
                        </div>
                      );
                    }
                    if (slice?.slice_type === "product_banner") {
                      const bannerSlice = slice as PrismicBlogPostDataBodyProductBanner;
                      if (bannerSlice) {
                        return (
                          <div key={uuid()}>
                            {bannerSlice?.items?.map((banner, i) => (
                              <ProductBanner key={i + uuid()} banner={banner} />
                            ))}
                          </div>
                        );
                      } else {
                        return <div key={index + uuid()} />;
                      }
                    }
                    if (slice?.slice_type === "report_slice_inside_post") {
                      const bannerSlice = slice as PrismicBlogPostDataBodyReportSliceInsidePost;
                      if (bannerSlice) {
                        return (
                          <div key={uuid()}>
                            {bannerSlice?.items?.map((banner, index) => (
                              <PostInsideBanner key={index + uuid()} banner={banner} />
                            ))}
                          </div>
                        );
                      } else {
                        return <div key={index + uuid()} />;
                      }
                    }
                    if (slice?.slice_type === "line") {
                      const line = slice as PrismicBlogPostDataBodyLine;
                      if (line) {
                        return (
                          <div className='line' key="line"></div>
                        );
                      } else {
                        return <div key={index + uuid()} />;
                      }
                    }
                    if (slice?.slice_type === "tip") {
                      const tipSlice = slice as PrismicBlogPostDataBodyTip;
                      const tip = tipSlice?.primary as PrismicBlogPostDataBodyTipPrimary;
                      return (
                        <div className="tip" key="tip">
                          <h5>
                            <img src={tip?.tip_icon?.url ?? ""} alt={tip?.tip_icon?.alt ?? ""} />
                            {RichText.asText(tip?.tip_title?.raw || [])}
                          </h5>
                          <p>{RichText.asText(tip?.tip_content?.raw || [])}</p>
                        </div>
                      );
                    }
                    return <div key={index + uuid()} />;
                  }
                )}
                <div className='labels-wrapper'>
                  {language === 'pl' ? <p>TAGI</p> : <p>TAGS</p>}
                  {labels()?.map(label => label?.length > 0 ?  <span key={label + uuid()} className="post-label">{label}</span> : null)}
                </div>
                <div className='share-social_wrapper'>
                  {language === 'pl' ? <p>UDOSTĘPNIJ ARTYKUŁ</p> : <p>SHARE ARTICLE</p>}
                  <div className="s9-widget-wrapper"></div>
                </div>
              </div>
              <div className="post-body__sidebar">
                <div>
                  {getSimilarPosts()}
                  <NewsletterBanner />
                </div>
              </div>
            </div>
          </div>
        </div>
        <InterestedBanner handleHubspot={handleShowMeeting}/>
      </BlogLayout>
    </>
  );
};
export default withPrismicPreview(PostDetails, [
  {
    repositoryName: 'carcavelos',
    linkResolver: linkResolver.linkResolver,
  },
]);
