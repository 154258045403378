import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";
import * as React from "react";
import { useState } from "react";
import { colors, productColors } from "shared/constants";
import styled, { css } from "styled-components";
import { PrismicProductHomeDataBodyCapabilitiesSlice } from "types/declarations";
import Button from "ui/ButtonComponent/Button";
import { useMediaQuery } from "utils/hooks/useMediaQuery";

const SectionWrapper = styled.section`
  background-color: ${colors.background};
`;

const CapabilitiesWrapper = styled.div(() => {
  const { mobile, tablet } = useMediaQuery()

  return css`
    padding: 50px 84px;
    max-width: 1440px;
    margin: 0 auto;

    ${tablet && 'padding: 40px 25px'}
    ${mobile && 'padding: 20px 15px'}
  `
});

const Title = styled.h1(() => {
  const { mobile } = useMediaQuery()

  return css`
    font-size: 28px;
    line-height: 36px;
    color: ${colors.darkBlue};

    ${mobile && css`
      font-size: 23px;
      line-height: 34px;
      max-width: 100%;
    `}
  `
});

interface ListElementProps {
  $active?: boolean
}

const ListElement = styled.div<ListElementProps>(({ $active }) => {
  const { mobile, tablet } = useMediaQuery();
  
  return css`
    display: flex;
    flex-direction: ${mobile ? 'column' : 'row'};
    align-items: ${mobile ? 'flex-start' : 'center'};
    justify-content: space-between;
    padding: ${tablet ? '20px 0 25px 20px;' : mobile ? '15px 15px 20px 15px;' : '35px 0 20px 35px;'};
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    background-color: ${productColors.white};
    margin: 20px 0;
    overflow: hidden;
    transition: max-height 0.3s ease, background-color 0.3s ease;
    max-height: ${$active ? 'fit-content' : 'auto'};

    ${$active && css`
    background-color: rgba(173, 170, 227, 0.2);
    border-left: 4px solid ${productColors.violet.dark};

      ${!mobile && css`
        img {
          transform: translateX(-40px);
          opacity: 1;
        }
      `}
    `}
  `
});

const StyledGatsbyImage = styled(GatsbyImage)<ListElementProps>(({ $active }) => {
  const { mobile, tablet } = useMediaQuery()

  return css`
    border-radius: 8px;
    width: ${tablet ? '42%' : mobile ? '160px' : '40%'};
    ${mobile
    ? css` 
        margin: 4px 0 12px 0;`
    : css`
        position: absolute;
        transition: transform 0.3s ease, opacity 0.3s ease;
        right: -105px;
        top: 25px;
        opacity: ${$active ? 1 : 0.5};
        ${$active && css`transform: translateX(-40px);`}
      `
    };
  `
});

const Textcontainer = styled.div(() => {
  const { mobile } = useMediaQuery()

  return css`
    display: flex;
    flex-direction: column;
    max-width: ${mobile ? '100%' : '65%'};
  `
});

const TileTitle = styled.h3(() => {
  const { mobile, tablet } = useMediaQuery()

  return css`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 18px;
    color: ${colors.darkBlue};

    ${tablet && css`
      font-size: 22px;
      line-height: 32px;
    `}

    ${mobile && css`
      font-size: 19px;
      line-height: 27px;
      margin-bottom: 10px;
    `}
  `
});

const Description = styled.div`
  margin-bottom: 0;

  p {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    margin-bottom: 5px;
    color: ${colors.darkBlue};
    padding-bottom: 15px;
  }
`;

const StyledButton = styled(Button)(() => {
  const { mobile } = useMediaQuery()
  
  return css`
    margin-top: ${mobile ? '0px' : '10px'};
    font-size: 1rem;
    max-height: 2.5rem;
    background-color: transparent;
    ${mobile && css`width: '100%';`}
  `
});

const Link = styled.a`
  width: fit-content;
`;

const StyledTitle = styled.h1(() => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    font-weight: 700;
    font-size: ${tablet ? '2.375rem' : mobile ? '1.75rem' : '2.75rem'};
    line-height: ${tablet ? '2.875rem' : mobile ? '2.125rem' : '3.375rem'};
    max-width: 900px;
    margin: auto;
    margin-bottom: 4rem;
    text-align: center;
    color: ${colors.darkBlue};
  `
})

const Capabilities: React.FC<{ slice: PrismicProductHomeDataBodyCapabilitiesSlice }> = ({ slice }) => {
  const { mobile } = useMediaQuery();
  const [activeElement, setActiveElement] = useState<number>(0);

  return (
    <SectionWrapper>
      <CapabilitiesWrapper>
        {slice?.primary?.capabilities_title_center
          ? (
            <StyledTitle>
              {RichText.asText(slice?.primary?.capabilities_section_title?.richText)}
            </StyledTitle>
          )
          : (
            <Title>
              {RichText.asText(slice?.primary?.capabilities_section_title?.richText)}
            </Title>
          )
        }
        {slice?.items?.map((item, index) => (
          <ListElement key={index} $active={activeElement === index} onClick={() => setActiveElement(index)}>
            {mobile 
              ? (
                <StyledGatsbyImage 
                  $active={activeElement === index}
                  image={item?.capabilities_element_image?.gatsbyImageData || ''} 
                  alt={item?.capabilities_element_image?.alt || ''} 
                />
              ) 
              : null
            }
            <Textcontainer>
                <TileTitle>
                  {RichText.asText(item?.capabilities_element_title?.richText)}
                </TileTitle>
                <Description>
                  {activeElement === index 
                    ? RichText.render(item?.capabilities_element_full_text?.richText)
                    : RichText.render(item?.capabilities_element_description?.richText)
                  }
                </Description> 
                {(activeElement === index && item?.capabilities_element_link?.raw.url)
                  ? (
                    <Link href={item?.capabilities_element_link?.raw.url}>
                      <StyledButton reverse minWidth={mobile ? '100%' : 'none'}>
                        {RichText.asText(item?.capabilities_element_button_text?.richText)}
                      </StyledButton>
                    </Link>
                  )
                  : null
                }
            </Textcontainer>
            {!mobile 
              ? (
                <StyledGatsbyImage 
                  $active={activeElement === index}
                  image={item?.capabilities_element_image?.gatsbyImageData || ''} 
                  alt={item?.capabilities_element_image?.alt || ''} 
                />
              )
              : null}
          </ListElement>
        ))}
      </CapabilitiesWrapper>
    </SectionWrapper>
  );
}

export default Capabilities;
