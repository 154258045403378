import React, { FC } from "react";
import "./ReportsItems.scss";
import { Link } from "gatsby";
import BtnArrowRight from "assets/arrowbtn.svg";
import { ReportItemProps } from "features/reports/items/posts/ReportsItem";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { RichText } from "prismic-reactjs";
import Arrow from "images/arrow-right.svg";
import { GatsbyImage } from "gatsby-plugin-image";
import styled, { css } from "styled-components";

const StyledGatsbyBigImage = styled(GatsbyImage)(({ theme }) => css`
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  height: 100%;
  object-fit: cover;
  width: 100%;
  @media (max-width: ${theme.breakpoints.tablet}) {
    height: 223px;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 0;
  }
`)

const BigReportsItem: FC<ReportItemProps> = ({ item }: ReportItemProps) => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const points = RichText.asText(item?.points?.raw || [])?.split(";");

  return (
    <Link to={item?.link + "/"}>
      <section className="big-report-item">
        <div className="big-report-item__left">
          <div className="tags">
            {item?.tags?.map(tag => (
              <span key={tag?.value} className="tag">
                {tag?.value}
              </span>
            ))}
          </div>
          <h2 className="big-report-item__title">{item?.title}</h2>
          <div className="big-report-item__description">
            {points?.map((point, index) => (
              <div
                key={index}
                style={{
                  margin: "10px 0",
                  display: "flex",
                  alignItems: "start",
                }}>
                <img
                  style={{ margin: "15px 15px 0 0" }}
                  src={Arrow}
                  alt="arrow"
                /> {point}
              </div>
            ))}
          </div>
          <div className="report-item__link">
            {language === "pl" ? "Dowiedz się więcej" : "Find out more"}
            <BtnArrowRight />
          </div>
        </div>
        <div className="big-report-item__right">
          <div className="newest">{language === "pl" ? "Najnowszy raport" : "Newest report"}</div>
          <StyledGatsbyBigImage
            image={item?.minImage?.gatsbyImageData ?? ''}
            alt={item?.minImage?.alt ?? 'colorful graphic'}
          />
        </div>
      </section>
    </Link>
  );
};

export default BigReportsItem;
