import React, { FC, useCallback } from "react";
import styled, {css} from 'styled-components'
import "./BlogItems.scss";
import { Tag } from "features/blog/blogModel";
import { BlogItemProps } from "features/blog/items/posts/BlogItem";
import { Link } from "gatsby";
import BtnArrowRight from "assets/arrowbtn.svg";
import Placeme from "assets/placeme-location-contact.svg";
import Target from "assets/placeme-target-contact.svg";
import Source from "assets/placeme-source-contact.svg";
import { useSelector } from "react-redux";
import { RootState } from "utils/store/store";
import { GatsbyImage } from "gatsby-plugin-image";

const Span = styled.span(({productColor}:{productColor: string})=>{
  return css`
  display: flex;
  align-items: center;
  color:${productColor};
  font-weight: 600;
  svg{
    margin-right: 12px;
    path{
      fill: ${productColor};
    }
  }
`
})

const StyledGatsbyImage = styled(GatsbyImage)`
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const BigBlogItem: FC<BlogItemProps> = ({ item }: BlogItemProps) => {
  const { language } = useSelector((state: RootState) => state.i18n);
  
  const getTags = useCallback((tag: Tag) => {
    const title = tag?.value;
    if (title?.includes("placeme")) {
      return (
        <Span productColor="#362FB7" className="big-blog-item__tags--tag">
          <Placeme width='18' height='18'/>
          {title}
        </Span>
      );
    } else if (title?.includes("target")) {
      return (
        <Span productColor="#CC3C00" className="big-blog-item__tags--tag">
          <Target width='18' height='18'/>
           {title}
       </Span>
      );
    } else if (title?.includes("source")) {
      return (
      <Span productColor="#6830B2" className="big-blog-item__tags--tag">
        <Source width='18' height='18'/>
         {title}
     </Span>
      );
    } else {
      return <span className="big-blog-item__tags--tag">{title}</span>;
    }
  }, []);

  return (
    <section className="big-blog-item">
      <div className="big-blog-item__left">
        <h1 className="big-blog-item__title">{item?.title}</h1>
        <div className="big-blog-item__tags">
          {item?.tags?.map(tag => (
            <React.Fragment key={tag?.value}>{getTags(tag)}</React.Fragment>
          ))}
        </div>
        <p className="big-blog-item__description">{item?.description}</p>
        <Link className="big-blog-item__link" to={language === 'pl' || language === 'en-pl' ? `${item?.link}/` : `/en-pl/blog/${item?.link}/`}>
          {language === 'pl' ? "Czytaj więcej": 'Read more'} <BtnArrowRight />
        </Link>
      </div>
      <div className="big-blog-item__right">
        <StyledGatsbyImage image={item?.image?.gatsbyImageData} alt={item?.image?.alt || 'big blog post image'} />
      </div>
    </section>
  );
};

export default BigBlogItem;
