import * as React from "react";
import "./Layout.scss";
import { FC } from "react";
import Header from "components/shared-components/header/Header";
import Footer from "features/footer/Footer";
import RootContainer from "containers/RootContainer";
import Microdata from "utils/seo/Microdata";
import styled, { css } from "styled-components";
import CookieConsentBanner from "./CookieConsentBanner";

const StyledMain = styled.main<{ backgroundColor?: string }>(({ backgroundColor }) => css`
  ${backgroundColor && css`background-color: ${backgroundColor};`}
`)

export interface LayoutProps {
  children: React.ReactNode;
  backgroundColor?: string
}

const Layout: FC<LayoutProps> = ({ children, backgroundColor }: LayoutProps) => (
  <RootContainer>
    <Header />
    <StyledMain backgroundColor={backgroundColor}>{children}</StyledMain>
    <Footer />
    <CookieConsentBanner />
    <Microdata />
  </RootContainer>
)

export default Layout
