import { usePrivacyPolicyData } from 'features/policy/hooks/usePrivacyPolicyData';
import { Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { useSelector } from 'react-redux';
import { pageUrls } from 'shared/constants'
import styled from 'styled-components';
import { RootState } from 'utils/store/store';

const TextWrapper = styled.div`
  display: flex;
`

const Title = styled.p`
  margin-right: 0.5rem;
  font-weight: 400;
`

const CookieConsentBanner = () => {
    const privacyPolicy = usePrivacyPolicyData()
    const { language } = useSelector((state: RootState) => state.i18n)
  
    return (
      <CookieConsent
        disableStyles
        buttonClasses='cookie-button'
        containerClasses='cookie-container'
        location='bottom'
        buttonText={RichText.asText(privacyPolicy?.cookies_bar_button_text?.richText)}
        cookieName={language === 'pl' ? 'gatsby-gdpr-google-tagmanager' : 'gatsby-gdpr-google-analytics'}
      >
        <TextWrapper>
          <Title>{RichText.asText(privacyPolicy?.cookies_bar_title?.richText)}</Title>
          <Link to={`/${language}${pageUrls.privacyPolicy}/`}>{RichText.asText(privacyPolicy?.link_text?.richText)}</Link>
        </TextWrapper>
      </CookieConsent>
    )
  }

export default CookieConsentBanner
