import { graphql, useStaticQuery } from 'gatsby'
import { useSelector } from 'react-redux'
import { RootState } from 'utils/store/store'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { PrismicPlacemeFunctionality, PrismicPlacemeFunctionalityDataType, PrismicPlacemeFunctionalityEdge } from '../types/PlacemeFunctionality'

export const usePlacemeFunctionalityData = (): PrismicPlacemeFunctionalityDataType => {
  const { language } = useSelector((state: RootState) => state.i18n)
  const result = useStaticQuery(graphql`
    {
      allPrismicPlacemeFunctionality {
        edges {
          node {
            lang
            _previewable
            data {
              body {
                ... on PrismicPlacemeFunctionalityDataBodyTalkSlice {
                  id
                  slice_type
                  primary {
                    talk_button_link {
                      raw
                    }
                    talk_button_text {
                      richText
                    }
                    talk_description {
                      richText
                    }
                    talk_image {
                      gatsbyImageData
                      alt
                      url
                    }
                    talk_title {
                      richText
                    }
                  }
                }
                ... on PrismicPlacemeFunctionalityDataBodyBenefitsSlice {
                  id
                  slice_type
                  items {
                    tile_content {
                      richText
                    }
                    tile_image {
                      gatsbyImageData
                      url
                      alt
                    }
                    tile_title {
                      richText
                    }
                  }
                  primary {
                    business_benefits_section_link {
                      url
                    }
                    business_benefits_section_title {
                      richText
                    }
                    flexible_tiles_width
                    section_background_color
                  }
                }
                ... on PrismicPlacemeFunctionalityDataBodyPredictionModelsSlice {
                  id
                  slice_type
                  items {
                    model_img {
                      gatsbyImageData
                      url
                      alt
                    }
                    model_description {
                      richText
                    }
                    model_title {
                      richText
                    }
                  }
                  primary {
                    button_text {
                      richText
                    }
                    button_url {
                      raw
                    }
                    title {
                      richText
                    }
                  }
                }
                ... on PrismicPlacemeFunctionalityDataBodyAccessDataSlice {
                  id
                  slice_type
                  items {
                    tile_description {
                      richText
                    }
                    tile_icon {
                      gatsbyImageData
                      url
                      alt
                    }
                    tile_icon_active {
                      url
                      alt
                    }
                    tile_image {
                      gatsbyImageData
                      alt
                      url
                    }
                    title_title {
                      richText
                    }
                  }
                  primary {
                    title {
                      richText
                    }
                    subtitle {
                      richText
                    }
                    button_link {
                      raw
                    }
                    button_text {
                      richText
                    }
                  }
                }
                ... on PrismicPlacemeFunctionalityDataBodyHeroCardSlice {
                  id
                  slice_type
                  primary {
                    title {
                      richText
                    }
                    description {
                      richText
                    }
                    left_button_text {
                      richText
                    }
                    left_button_url {
                      raw
                    }
                    right_button_text {
                      richText
                    }
                    right_button_url {
                      raw
                    }
                    pointer_img {
                      url
                      alt
                    }
                    video_overlay_img {
                      url
                      alt
                    }
                    video_url {
                      raw
                    }
                    video_play_button_text {
                      richText
                    }
                    footer {
                      richText
                    }
                    section_background_color
                  }
                }
                ... on PrismicPlacemeFunctionalityDataBodyCapabilitiesSlice {
                  id
                  slice_type
                  primary {
                    capabilities_section_title {
                      richText
                    }
                    capabilities_title_center
                  }
                  items {
                    capabilities_element_button_text {
                      richText
                    }
                    capabilities_element_description {
                      richText
                    }
                    capabilities_element_full_text {
                      richText
                    }
                    capabilities_element_image {
                      gatsbyImageData
                      alt
                      url
                    }
                    capabilities_element_link {
                      raw
                    }
                    capabilities_element_title {
                      richText
                    }
                  }
                }
                ... on PrismicPlacemeFunctionalityDataBodyTitleDescriptionSlice {
                  id
                  slice_type
                  primary {
                    description {
                      richText
                    }
                    title {
                      richText
                    }
                    full_width_text
                    higher_section
                    background_color
                  }
                }
              }
              seo_title {
                text
              }
              seo_description {
                text
              }
              seo_canonical_url {
                url
              }
            }
          }
        }
      }
    }
  `)
 
  const { data } = useMergePrismicPreviewData(result)

  return data.allPrismicPlacemeFunctionality.edges
  .map((edge: PrismicPlacemeFunctionalityEdge) => edge.node)
    .filter((node: PrismicPlacemeFunctionality) => node.lang === language)
      .map((node: PrismicPlacemeFunctionality) => node.data)[0]
}
