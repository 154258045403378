import { graphql, useStaticQuery } from 'gatsby';
import {
  PrismicBlogPost,
  PrismicBlogPostDataType,
  PrismicBlogPostEdge,
  PrismicHomepage,
  PrismicHomepageDataType,
  PrismicHomepageEdge,
} from '../../../../types/declarations';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/store/store';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

export const useBlogPost = (): PrismicBlogPostDataType[] => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicBlogPost {
        edges {
          node {
            lang
            _previewable
            data {
              link {
                text
              }
              section_title {
                raw
              }
              section_header {
                raw
              }
              seo_title {
                raw
              }
              seo_description {
                raw
              }
              seo_canonical_url {
                url
              }
              blog_image {
                gatsbyImageData
                url
                alt
              }
              order {
                text
              }
            }
          }
        }
      }
      allPrismicHomepage {
        edges {
          node {
            lang
            _previewable
            data {
              blog_post_main_title {
                raw
              }
              blog_post_header {
                raw
              }
              blog_post_main_button {
                raw
              }
              blog_post_read_more_button {
                raw
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicBlogPost.edges
    .map((edge: PrismicBlogPostEdge) => edge.node)
    .filter((node: PrismicBlogPost) => node.lang === language)
    .map((node: PrismicBlogPost) => node.data);
};

export const useBlogPostHomePageData = (): PrismicHomepageDataType => {
  const { language } = useSelector((state: RootState) => state.i18n);
  const resultS = useStaticQuery(graphql`
    {
      allPrismicBlogPost {
        edges {
          node {
            lang
            _previewable
            data {
              link {
                text
              }
              section_title {
                raw
              }
              section_header {
                raw
              }
              seo_title {
                raw
              }
              seo_description {
                raw
              }
              seo_canonical_url {
                url
              }
              blog_image {
                gatsbyImageData
                url
                alt
              }
              order {
                text
              }
            }
          }
        }
      }
      allPrismicHomepage {
        edges {
          node {
            lang
            _previewable
            data {
              blog_post_main_title {
                raw
              }
              blog_post_header {
                raw
              }
              blog_post_main_button {
                raw
              }
              blog_post_read_more_button {
                raw
              }
            }
          }
        }
      }
    }
  `);
  const {data: result} = useMergePrismicPreviewData(resultS)
  return result.allPrismicHomepage.edges
    .map((edge: PrismicHomepageEdge) => edge.node)
    .filter((node: PrismicHomepage) => node.lang === language)
    .map((node: PrismicHomepage) => node.data)[0];
};
