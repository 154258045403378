import { graphql, useStaticQuery } from 'gatsby'
import { useSelector } from 'react-redux'
import { RootState } from 'utils/store/store'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { PrismicPrivacyPolicy, PrismicPrivacyPolicyDataType, PrismicPrivacyPolicyEdge } from '../types/PrivacyPolicy'

export const usePrivacyPolicyData = (): PrismicPrivacyPolicyDataType => {
  const { language } = useSelector((state: RootState) => state.i18n)
  const result = useStaticQuery(graphql`
    {
      allPrismicPrivacyPolicy {
        edges {
          node {
            lang
            _previewable
            data {
              content {
                richText
              }
              cookies_bar_button_text {
                richText
              }
              cookies_bar_title {
                richText
              }
              link_text {
                richText
              }
            }
          }
        }
      }
    }
  `)
 
  const { data } = useMergePrismicPreviewData(result)

  return data.allPrismicPrivacyPolicy.edges
  .map((edge: PrismicPrivacyPolicyEdge) => edge.node)
    .filter((node: PrismicPrivacyPolicy) => node.lang === language)
      .map((node: PrismicPrivacyPolicy) => node.data)[0]
}
