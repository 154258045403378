import React, { ReactNode } from "react";
import { colors, productColors } from "shared/constants";
import styled, { css } from "styled-components";
import { useMediaQuery } from "utils/hooks/useMediaQuery";
import { StyledButton } from "../SuccessStories";
import BtnArrowRight from "assets/arrowRight.svg";
import QuoteIcon from "assets/quoteIcon.svg";
import { Maybe } from "types/declarations";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

const ContentWrapper = styled.div(() => {
  const { tablet, mobile } = useMediaQuery()
  
  return css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    ${!tablet && !mobile && css`max-width: 57%;`}
  `
});

const Title = styled.h1(() => {
  const { mobile, tablet } = useMediaQuery()
    
  return css`
    font-size: 40px;
    line-height: 52px;
    color: ${colors.darkBlue};

    ${tablet && css`
    font-size: 37px;
    line-height: 52px;
    `}

    ${mobile && css`
    font-size: 30px;
    line-height: 40px;
    `}
  `
});
  
const SubTitle = styled.h1(() => {
  const { mobile, tablet  } = useMediaQuery()
      
  return css`
    font-size: 24px;
    line-height: 32px;
    margin: 14px 0;
    color: ${colors.darkBlue};

    ${tablet && css`
    font-size: 22px;
    line-height: 30px;
    `}

    ${mobile && css`
    font-size: 19px;
    line-height: 27px;
    `}
  `
});
  
const Description = styled.div(() => {
  const { mobile } = useMediaQuery()
    
  return css`
    margin-bottom: 0;
    
    p {
    color: ${colors.darkBlue};
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    margin-bottom: ${mobile ? '14px' : '5px'};
    padding-bottom: 0;
    }
  `
});
  
const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  img {
    height: 80px;
    width: auto;
    border-radius: 20px;
  }
`;
  
const Quote = styled.span(() => {
  const { mobile } = useMediaQuery()
  
  return css`
    font-size: 14px;
    color: ${productColors.dark};
    opacity: 0.6;
    margin: 18px 0;

    svg {
    margin-right: 10px;
    }

    ${mobile && css`margin: 0;`}
  `
});

interface CarouselItemContentProps {
  title: string
  imgUrl: IGatsbyImageData
  imgAlt: Maybe<string> | undefined
  subtitle: string
  description: ReactNode
  author: string
  buttonText: string
  linkUrl: string
}
  
const CarouselItemContent = ({ title, imgUrl, imgAlt, subtitle, description, author, buttonText, linkUrl }: CarouselItemContentProps) => {
  const { mobile, tablet } = useMediaQuery();

  return (
    <ContentWrapper>
      <Title>{title}</Title>
      <StyledGatsbyImage image={imgUrl}  alt={imgAlt || ''} />
      <SubTitle>{subtitle}</SubTitle>
      <Description>{description}</Description>
      <Quote>
        <QuoteIcon />
        {author}
      </Quote>
      {!tablet && !mobile 
        ? (
          <a href={linkUrl}>
            <StyledButton reverse minWidth={mobile ? '100%' : 'none'}>
            {buttonText}
            <BtnArrowRight />
            </StyledButton>
          </a>
        )
        : null
      }
    </ContentWrapper>
  )
}

export default CarouselItemContent;
